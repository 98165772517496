<template>
  <v-container>
    <v-card flat>
      <v-toolbar
        dense
        height="40"
        color="transparent"
        elevation="1"
        dark
      >
        <h3 class="red--text">
          CLIENTE
        </h3>
      </v-toolbar>
      
      <v-row>
        <v-col>
          <v-checkbox
            v-model="acc1"
            dense
            label="Visita"
            hide-details
          />
          <v-checkbox
            v-model="acc2"
            dense
            label="Zoom"
            hide-details
          />
          <v-checkbox
            v-model="acc1"
            dense
            label="Mensaje"
            hide-details
          />
          <v-checkbox
            v-model="acc1"
            dense
            label="Llamada"
            hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          lg="12"
          md="6"
          xs="12"
          sm="12"
        >
          <v-toolbar
            flat
            color="transparent"
            dense
            height="40"
          >
            <h3>Acciones en la visita</h3>
          </v-toolbar>

          <v-checkbox
            v-model="acc1"
            label="Se hablo con el decisor"
            hide-details
            dense
          />
          
          <v-checkbox
            v-model="acc2"
            label="Levantamiento informacion"
            hide-details
            dense
          />

          <v-checkbox
            v-model="acc3"
            label="Se entregó cotización"
            hide-details
            dense
          />

          <v-checkbox
            v-model="acc4"
            label="Seguimiento a cotización"
            hide-details
            dense
          />

          <v-checkbox
            v-model="acc5"
            label="Asesoría técnica"
            hide-details
            dense
          />

          <v-checkbox
            v-model="acc6"
            label="Seguimiento a pruebas"
            hide-details
            dense
          />

          <v-checkbox
            v-model="acc7"
            label="Se hizo presentacion o demo"
            hide-details
            dense
          />

          <v-checkbox
            v-model="acc8"
            label="Acciones de cobranza"
            hide-details
            dense
          />

          <v-checkbox
            v-model="acc9"
            label="Se atendió queja"
            hide-details
            dense
          />

          <v-checkbox
            v-model="acc10"
            label="Recorrido para nuevas oportunidades"
            hide-details
            dense
          />

          <v-checkbox
            v-model="acc5"
            label="Otros"
            dense
            hide-details
          />
        </v-col>

        <v-col
          cols="12"
          lg="12"
          md="6"
          xs="12"
          sm="12"
        >
          <v-toolbar
            flat
            color="transparent"
            dense
            height="40"
          >
            <h3>Resultado</h3>
          </v-toolbar>

          <v-row>
            <v-col
              cols="12"
            >
              <v-checkbox
                v-model="acc5"
                label="Se levanto pedido"
                dense
                hide-details
              />
          
              <v-text-field
                dense
                label="Folio"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                v-model="acc5"
                label="Se entrego cotización"
                hide-details
                dense
              />
              <v-text-field
                dense
                label="Folio"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-checkbox
                v-model="acc5"
                dense
                label="Se atendio pendiente"
                hide-details
              />
              <v-text-field
                dense
                label="Folio"
              />
            </v-col>
          </v-row>

         
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                label="Hora de inicio"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-time-picker
              v-if="menu2"
              v-model="time"
              full-width
              @click:minute="$refs.menu.save(time)"
            />
          </v-menu>
           
          <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                label="Hora de termino"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-time-picker
              v-if="menu2"
              v-model="time"
              full-width
              @click:minute="$refs.menu.save(time)"
            />
          </v-menu>
            
          <v-textarea
            outlined
            auto-grow
            rows="3"
            row-height="15"
            label="Notas para recordar"
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      checkbox1: false,
      checkbox2: false,
      acc1:'',
      acc2:'',
      acc3:'',
      acc4:'',
      acc5:'',
      acc6:'',
      acc7:'',
      acc8:'',
      acc9:'',
      acc10:'',
      acc11:'',
      acc12:'',




      time: null,
      menu2: false,
      modal2: false,
    }
  },
}
</script>